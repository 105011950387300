
  import { hydrateOnInteraction, hydrateWhenIdle, hydrateWhenVisible, hydrateSsrOnly } from 'vue-lazy-hydration';
  import { toPascalCase } from '@/utils/cases';
  import propsinterpolationMixin from '@/plugins/propsinterpolationMixin';

  export default {
    name: 'Dynamic',
    inheritAttrs: false,
    props: {
      component: {
        type: String,
        default: null,
      },
      hydration: {
        type: String,
        default: 'WhenVisible',
      },
      parseResources: {
        type: Array,
        default: () => [],
      },
      componentProps: Object,
    },
    mixins: [propsinterpolationMixin],
    computed: {
      hydrate() {
        return this[`hydrate${this.hydration}`] ?? null;
      },
      name() {
        return this.component ?? this.$attrs.name;
      },
      componentLoader() {
        const loaders = ['', ...this.$nuxtDynamic.prefixes]
          .map((prefix) => {
            const name = `Lazy${toPascalCase(prefix)}${toPascalCase(this.name)}`;
            return name in this.$nuxtDynamic.loaders ? this.$nuxtDynamic.loaders[name] : null;
          })
          .filter((loader) => loader);
        return loaders.shift() ?? null;
      },
      lazyComponent() {
        return this.hydrate && this.componentLoader ? this.hydrate(this.componentLoader) : this.name;
      },
    },
    methods: {
      hydrateOnInteraction: hydrateOnInteraction,
      hydrateWhenIdle: hydrateWhenIdle,
      hydrateWhenVisible: hydrateWhenVisible,
      hydrateNever: hydrateSsrOnly,
    },
  };
