export default {
  created() {
    const errorFields = [];
    const iterate = (obj) => {
      //page heading
      if (!obj) return;
      Object.keys(obj).forEach((key) => {
        if (obj[key]) {
          if (typeof obj[key] === 'object') {
            iterate(obj[key]);
          } else {
            if (typeof obj[key] === 'string') {
              const regular = new RegExp('\\${([A-Za-z0-9\\-\\_]+)\\}', 'g');
              if (obj[key].match(regular)) {
                obj[key] = obj[key].replace(regular, (matchWithDollarSign) => {
                  const match = matchWithDollarSign.match('\\{(.*)\\}');
                  const field = match[match.index];
                  if (field) {
                    const item = this.parseResources.find((i) => i.key === field || i.orkey === field);
                    if (!item) {
                      errorFields.push(field);
                      return '';
                    }
                    return item.resource[item.key] || item.resource[item.orkey] || '';
                  }
                  return '';
                });
              }
            }
          }
        }
      });
    };
    iterate(this.componentProps);
    if (errorFields.length) {
      console.log(`${errorFields.join(', ')} resource not not declared`);
    }
  },
};
